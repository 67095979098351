<template>
    <a-select
        mode="multiple"
        v-model="selectedTags"
        style="width: 100%"
    >
        <a-select-option v-for="(tag,tagI) in tags" :key="tag+tagI" :value="tag.id">
            <b>{{tag.name}}</b> <span style="font-style: italic;"> from {{tag.instName}}</span>
        </a-select-option>
    </a-select>
</template>

<script>
export default {
    props:{
        value:{
            type:Array,
            required:true,
            default: []
        },
    },
    data(){
        return {
            tags:[],
            selectedTags:[]
        }
    },
    computed:{
        instances(){
            let {children = [],...instance} = this.$store.state.instance
            let options =  [instance,...children]
            return options.map( ({id,name}) => ({
                label:name,
                value:id
            }))
        },
    },
    watch:{
        selectedTags:{
            handler(val,oldVal){
				console.log('Selected Tags', val)
				val = val.map(x => x = `"${x}"`);
                return this.$emit('input', val)
            }
        }
    },
    created(){
        if (this.value && this.value.length){
			this.value = this.value.map(x => x = x.replaceAll('"', ''))
            this.selectedTags = this.value
        }
        console.log('INSTANCESSSSS', this.instances)
        this.tags = []
        this.instances.forEach(x => {
            this.$api.get(`/tags/${x.value}`).then( ({data}) => {
                console.log('TAG DATA COMING BACK', data)
                data.forEach(y => {
                    if (y.type = "lots"){
                        y.instName = x.label
                        this.tags.push(y)
                    }
                })
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        })

    },
    mounted(){

    }
}
</script>

<style scoped lang="scss">
    .button-editor-container{
        padding:10px;
        border:1px solid #eee;

    }
</style>
